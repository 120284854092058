import React from "react";
import styled from "styled-components";
import { IconSet } from "./IconSet";
import { HeaderNav } from "./HeaderNav";
import { stateType } from "../Context/AppContext";

const WindowStyle = styled.div`
  background-color: #bfbfbf;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

const Header = styled.div`
  display: flex;
  height: 20px;
  background-color: #07007f;
  align-items: center;
  padding: 0 5px;
  justify-content: space-between;
`;

const HeaderText = styled.div`
  color: #fff;
`;

type WindowProps = {
  headerText: string;
  setIsOpen: (state: stateType) => void;
};

export const WindowBar = ({ headerText, setIsOpen }: WindowProps) => {
  return (
    <div>
      <Header>
        <HeaderText>{headerText}</HeaderText>
        <IconSet setState={setIsOpen} />
      </Header>
      <WindowStyle>
        <>
          <HeaderNav />
        </>
      </WindowStyle>
    </div>
  );
};
