import React from "react";
import styled from "styled-components";
import Logo from "../assets/Wnds.png";

const StartButtonStyle = styled.div<{ isStartOpen: boolean }>`
  background-color: #bfbfbf;
  user-select: none;
  width: 90px;
  height: 25px;
  margin: 3px;
  border-width: 2px;
  border-style: ${(props) => (props.isStartOpen ? "inset" : "solid")};
  border-color: ${(props) =>
    props.isStartOpen ? `#000 #fff #fff #000` : `#fff #000 #000 #fff`};

  font-size: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;

  &::before {
    ${(props) =>
      props.isStartOpen
        ? `position: absolute;
    border: 1px dotted #000;
    width: 82px;
    height: 20px;
    margin-top: 0px;
    margin-left: 0px;
    content: "";`
        : ""}
  }
`;

const WindowsLogoWrapper = styled.img`
  width: 28px;
  height: 24px;
  margin: 10px 0px;
  transform: rotate(-10deg);
`;

export const StartButton = (props: {
  setIsStartOpen: (_isStartOpen: boolean) => void;
  isStartOpen: boolean;
}) => {
  return (
    <StartButtonStyle
      isStartOpen={props.isStartOpen}
      onClick={() => props.setIsStartOpen(!props.isStartOpen)}
    >
      <WindowsLogoWrapper src={Logo} alt={"A windows logo"} />
      Start
    </StartButtonStyle>
  );
};
