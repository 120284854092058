import React from "react";
import styled from "styled-components";
import Close from "../assets/cross.png";
import Min from "../assets/min.png";
import { stateType } from "../Context/AppContext";

const IconSetStyle = styled.div`
  display: flex;
  flex-direction: row;
  width: 30px;
  justify-content: space-around;
`;

const ImgIcon = styled.img`
  border-top: 1px solid #fff;
  border-bottom: 1px solid #000;
  margin: 0 1px;
`;

type IconProps = {
  setState: (state: stateType) => void;
};

export const IconSet = ({ setState }: IconProps) => {
  return (
    <IconSetStyle>
      <ImgIcon
        onClick={() => setState(stateType.minimised)}
        src={Min}
        alt={"Minimise Button"}
      />
      <ImgIcon
        onClick={() => setState(stateType.closed)}
        src={Close}
        alt={"Close Button"}
      />
    </IconSetStyle>
  );
};
