import React, { useEffect } from "react";
import styled from "styled-components";
import { Separator } from "../Separator";
import { WindowBar } from "../WindowBar";
import { useAppContext } from "../../Context/AppContext";
import MSPaint from "../../assets/MSpaintttt.jpg";
import MSPaintFooter from "../../assets/MSpaintfooter.jpg";
import Arrow from "../../assets/arrow.png";
import DownArrow from "../../assets/downarrow.png";
import Draggable from "react-draggable"; // The default

import { MediaItem } from "../MediaItem";
import { setTopIndex } from "../../App";

const PageWrapper = styled.div`
  background-color: #c5c6cb;
  width: 90%;
  max-width: 600px;
  border: 2px solid #fff;
  border-color: #fff #484848 #484848 #fff;
  display: flex;
  flex-direction: column;
  margin-top: 5%;
  margin-left: 7%;
  position: absolute;
`;

const Container = styled.div`
  position: relative;
  margin: 1px;
  margin-top: 3px;
  display: flex;
  flex-direction: row;
  font-family: "Roboto Mono", monospace;
  min-height: 300px;
  text-align: left;
  flex-wrap: wrap;
  margin: 0 7px;
`;

const MSPaintStyle = styled.img`
  position: absolute;
  width: 60px;
`;

const TextWrapper = styled.div`
  font-family: "Comic Sans MS", "Comic Sans", cursive;
  margin-left: 15px;
  margin-top: 15px;
  padding: 0 20px 20px 0;
  overflow-y: scroll;
  max-height: 400px;

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 0px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 15px;
    background-color: #bebebe;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #bebebe;
    border: 2px solid #bebebe;
    border-color: #fff #bebebe #000 #e2e2e2;
  }
`;

const HeadingText = styled.div`
  font-size: 23px;
  font-weight: 800;
  text-decoration: underline;
`;

const Canvas = styled.div`
  background-color: #fff;
  width: 90%;
  margin-left: 70px;
  height: 100%;
  border-width: 2px;
  border-color: #ccc #e1e1e1 #e1e1e1 #ccc;
  border-style: inset;
`;

const Footer = styled.img`
  height: 40px;
  width: 207px;
`;

const ScrollArrow = styled.img`
  width: 16px;
  height: 15px;
  position: absolute;
  top: 3px;
  right: 1px;
`;

const DownScrollArrow = styled.img`
  width: 17px;
  height: 15px;
  position: absolute;
  bottom: 42px;
  right: 7px;
`;

export const MediaPage = () => {
  const { setFeaturesState, isTouchDevice } = useAppContext();

  useEffect(() => {
    setTopIndex("features");
  }, []);

  const DragWrapper: React.FunctionComponent = (props) => {
    return isTouchDevice ? (
      <>{props.children}</>
    ) : (
      <Draggable>{props.children}</Draggable>
    );
  };

  return (
    <DragWrapper>
      <PageWrapper
        id="features"
        onClick={(e) => setTopIndex(e.currentTarget.id)}
      >
        <WindowBar setIsOpen={setFeaturesState} headerText={"media features"} />
        <Separator />
        <Container>
          <ScrollArrow src={Arrow} />
          <MSPaintStyle src={MSPaint} />
          <Canvas>
            <TextWrapper>
              <HeadingText>Media and Appearances</HeadingText>
              <MediaItem
                subtitle={"Computer Weekly"}
                body={"Booking.com celebrates women in tech with annual awards"}
                link="https://www.computerweekly.com/news/252481554/Bookingcom-celebrates-women-in-tech-with-annual-awards"
              />
              <MediaItem
                subtitle={"Booking.com"}
                body={
                  "Booking.com Announces Winners of its Technology Playmaker Awards 2020"
                }
                link="https://globalnews.booking.com/bookingcom-announces-winners-of-its-technology-playmaker-awards-2020/"
              />
              <MediaItem
                subtitle={"Marmalade Film and Media"}
                body={
                  "How can VR be a force for good in helping those with dementia"
                }
                link="https://marmaladefilmandmedia.com/creative-agency-blog-the-jam/conversation-cecil-adjalo-k4f5k-grpeg"
              />
              <MediaItem
                subtitle={"BBC Bitesize Careers"}
                body={"How to become a software developer: Hannah's story"}
                link="https://www.bbc.co.uk/bitesize/articles/zb9nd6f"
              />
              <MediaItem
                subtitle={"Sky News Live with Ian King"}
                body={"Live interview on Sky News with Ian King about vrcalm"}
                link="https://twitter.com/erhannah/status/1104372644600791041"
              />
              <MediaItem
                subtitle={"Evening Standard"}
                body={
                  "Why Sky gave these three young women £25,000 each to develop their own tech idea"
                }
                link="https://www.standard.co.uk/tech/sky-tech-scholars-kike-oniwinde-hannah-blair-oishi-deb-a3968211.html"
              />
              <MediaItem
                subtitle={"The Times"}
                body={"App and away: £25000 each from Sky for tech stars"}
                link="https://www.thetimes.co.uk/article/app-and-away-25-000-each-from-sky-for-tech-stars-xhx9gbngv"
              />
              <MediaItem
                subtitle={"University of Surrey Press Centre"}
                body={"Surrey student wins Women in Technology Scholars grant"}
                link="https://www.surrey.ac.uk/news/surrey-student-wins-women-technology-scholars-grant"
              />
              <MediaItem
                subtitle={"University of Surrey Press Centre"}
                body={
                  "My Professional Training year – Hannah Blair, The Walt Disney Company"
                }
                link="https://blogs.surrey.ac.uk/careers/2017/08/03/my-professional-training-year-hannah-blair-the-walt-disney-company/"
              />
              <MediaItem
                subtitle={"News Shopper"}
                body={
                  "Bromley student awarded Undergraduate of the Year for Innovation"
                }
                link="https://www.newsshopper.co.uk/news/yournews/15345424.bromley-student-awarded-undergraduate-of-the-year-for-innovation/"
              />
              <MediaItem
                subtitle={"Wooga"}
                body={
                  "We are inviting female students to attend UNITE Berlin. These are our 2018 winners!"
                }
                link="https://blog.wooga.com/we-are-inviting-five-female-students-to-attend-unite-berlin-these-are-our-2018-winners-8f8d2e9a63ea"
              />
              <MediaItem
                subtitle={"University of Surrey Press Centre"}
                body={
                  "University of Surrey student wins Undergraduate of the Year for Innovation award"
                }
                link="https://www.surrey.ac.uk/mediacentre/press/2017/university-surrey-student-wins-undergraduate-year-innovation-award"
              />
              <MediaItem
                subtitle={"Stemettes"}
                body={
                  "5 Student Stemettes are heading to Texas this week. Here’s why."
                }
                link="https://stemettes.org/blog/2016/10/16/5-student-stemettes-are-heading-to-texas-this-week-heres-why/"
              />
              <MediaItem
                subtitle={"The Hut Group"}
                body={
                  "The Hut Group taps tech talent at leading UK universities in inaugural programming contest"
                }
                link="https://www.thg.com/blog/2014/04/17/the-hut-group-taps-tech-talent-at-leading-uk-universities-in-inaugural-programming-contest/"
              />
              <MediaItem
                subtitle={"Network Rail"}
                body={
                  "Schoolgirls raise the roof with IT competition work experience prize"
                }
                link="https://www.networkrailmediacentre.co.uk/news/schoolgirls-raise-the-roof-with-it-competition-work-experience-prize"
              />
            </TextWrapper>
          </Canvas>
        </Container>
        <DownScrollArrow src={DownArrow} />
        <Footer src={MSPaintFooter} />
      </PageWrapper>
    </DragWrapper>
  );
};
