import React from "react";
import styled from "styled-components";
import TabBG from "../assets/TabBG.png";
import { stateType } from "../Context/AppContext";

const NavTab = styled.div<{ state: stateType }>`
  height: 25px;
  margin: 3px 1px;
  font-size: 18px;
  display: flex;
  justify-content: left;
  flex-wrap: no-wrap;
  align-items: center;
  padding: 0 5px;
  overflow: hidden;
  flex: 1 1 auto;

  ${(props) =>
    props.state === stateType.open
      ? `background-color: rgba(226, 226, 226, 0.6);
  border-width: 2px;
  border-color: #000 #fff #fff #000;
  border-style: inset;
  background-image: url(${TabBG});
  background-size: 25%;
  background-blend-mode: color;
`
      : `border-width: 2px;
  border-color: #fff #000 #000 #fff;
  border-style: solid;`}
`;

const InternetIcon = styled.img`
  width: 20px;
  margin: 0px 2px;
`;

const OptionTextStyle = styled.div`
  margin-top: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
`;
type NavProps = {
  OptionText: string;
  setState: (_state: stateType) => void;
  state: stateType;
  icon: string;
  id: string;
};

export const NavigationTab = ({
  OptionText,
  setState,
  state,
  icon,
  id,
}: NavProps) => {
  const toggleState = () => {
    if (state === stateType.minimised) {
      return stateType.open;
    }
    return stateType.minimised;
  };
  return (
    <NavTab
      state={state}
      style={{ backgroundImage: "../assets/coffee.png" }}
      className={"flex"}
      onClick={() => {
        setState(toggleState());
      }}
    >
      <InternetIcon src={icon} alt={"Coffee Logo"} />
      <OptionTextStyle>{OptionText}</OptionTextStyle>
    </NavTab>
  );
};
