import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { StartButton } from "./StartButton";
import { NavigationTab } from "./NavigationTab";
import Folder from "../assets/folder.png";
import Shutdown from "../assets/shutdown.png";
import Programs from "../assets/programs.png";
import Contact from "../assets/contact.png";
import MSDOS from "../assets/MSDOS.png";
import { Separator } from "./Separator";
import { useAppContext } from "../Context/AppContext";
import { stateType } from "../Context/AppContext";
import { NavClock } from "./NavClock";
import NetIcon from "../assets/neticon.png";
import PaintIcon from "../assets/painticon.png";
import cmdLogo from "../assets/cmdlogo.png";
import Solitaire from "../assets/solitaireicon.png";

const NavBar = styled.div`
  position: relative;
  background-color: #bfbfbf;
  width: 99.6vw;
  height: 35px;
  border: 2px solid #fff;
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: row;
  margin-right: 2px;
  justify-content: space-between;
  align-items: center;
  z-index: 5;
`;

const StartOptionsWrapper = styled.div`
  position: absolute;
  height: 360px;
  width: 230px;
  background-color: #bebebe;
  margin-top: -364px;
  border: 2px solid #000;
  border-color: #e2e2e2 #000 #000 #e2e2e2;
  display: flex;
  flex-direction: row;
  font-family: "MSSS";
  font-size: 10px;
`;

const SideBar = styled.div`
  width: 40px;
  height: 100%;
  background-color: #808080;
`;

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const NavBarOption = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
`;

const ImgIcon = styled.img`
  width: 35px;
  height: 33px;
  margin-top: 5px;
`;

const OptionGroup = styled.div`
  display: grid;
  grid-template-columns: 40px auto;
  padding: 5px;
  color: #000;
  &:hover {
    background-color: #070081;
    color: #fff;
  }
`;

const SideBarText = styled.div`
  font-family: "Arial-Black";
  font-size: 25px;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  margin-top: 230px;
  color: #bebebe;
  filter: blur(0.5px);

  @media screen and (max-width: 767px) {
    display: none;
  }
`;
const SideBarText95 = styled.div`
  font-family: "Anonymous Pro", monospace;
  font-size: 27px;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  margin-top: -148px;
  margin-left: 7px;
  color: #cecece;
  font-weight: 100;
  font-stretch: expanded;
  letter-spacing: -2px;
  filter: blur(0.5px);

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 70%;
`;

const NavWrapper = styled.div`
  display: flex;
  flex-direction: row;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  align-content: start;
  max-width: 63vw;

  @media screen and (max-width: 375px) {
    max-width: 56vw;
  }
`;

export const Navigation = () => {
  const [isStartOpen, setIsStartOpen] = useState(false);
  const {
    aboutState,
    featuresState,
    projectState,
    contactState,
    setAboutState,
    setFeaturesState,
    setProjectState,
    setContactState,
    setSkillsState,
    skillsState,
    awardsState,
    setAwardsState,
  } = useAppContext();
  const ref = useRef<HTMLInputElement>(null);
  const elements = [
    {
      state: aboutState,
      text: `hannah.html`,
      setState: setAboutState,
      icon: NetIcon,
      id: "about",
    },
    {
      state: featuresState,
      text: `media.bmp`,
      setState: setFeaturesState,
      icon: PaintIcon,
      id: "features",
    },
    {
      state: projectState,
      text: `stuff_ive_done.html`,
      setState: setProjectState,
      icon: NetIcon,
      id: "projects",
    },
    {
      state: contactState,
      text: `get_in_touch.txt`,
      setState: setContactState,
      icon: NetIcon,
      id: "contact",
    },
    {
      state: skillsState,
      text: `MS-DOS Prompt`,
      setState: setSkillsState,
      icon: cmdLogo,
      id: "skills",
    },
    {
      state: awardsState,
      text: `awards&talks.exe`,
      setState: setAwardsState,
      icon: Solitaire,
      id: "awards",
    },
  ];

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        setIsStartOpen(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <NavBar ref={ref} className={"flex"}>
      <RowContainer>
        <StartButton
          setIsStartOpen={setIsStartOpen}
          isStartOpen={isStartOpen}
        />
        {isStartOpen && (
          <StartOptionsWrapper>
            <SideBar>
              <SideBarText>Windows</SideBarText>
              <SideBarText95>95</SideBarText95>
            </SideBar>
            <OptionsWrapper>
              <OptionGroup
                onClick={() => {
                  setAboutState(stateType.open);
                  setIsStartOpen(false);
                }}
              >
                <ImgIcon src={Folder} />
                <NavBarOption>About</NavBarOption>
              </OptionGroup>
              <OptionGroup
                onClick={() => {
                  setProjectState(stateType.open);
                  setIsStartOpen(false);
                }}
              >
                <ImgIcon src={Programs} />
                <NavBarOption>Projects</NavBarOption>
              </OptionGroup>
              <OptionGroup
                onClick={() => {
                  setSkillsState(stateType.open);
                  setIsStartOpen(false);
                }}
              >
                <ImgIcon src={MSDOS} />
                <NavBarOption>Skills</NavBarOption>
              </OptionGroup>
              <OptionGroup
                onClick={() => {
                  setFeaturesState(stateType.open);
                  setIsStartOpen(false);
                }}
              >
                <ImgIcon src={Folder} />
                <NavBarOption>Media</NavBarOption>
              </OptionGroup>
              <OptionGroup
                onClick={() => {
                  setAwardsState(stateType.open);
                  setIsStartOpen(false);
                }}
              >
                <ImgIcon src={Solitaire} />
                <NavBarOption>Awards & Talks</NavBarOption>
              </OptionGroup>
              <OptionGroup
                onClick={() => {
                  setContactState(stateType.open);
                  setIsStartOpen(false);
                }}
              >
                <ImgIcon src={Contact} />
                <NavBarOption>Contact</NavBarOption>
              </OptionGroup>
              <Separator />
              {/* <OptionGroup
                onClick={() => {
                  setIsStartOpen(false);
                }}
              >
                <ImgIcon src={Settings} />
                <NavBarOption>Settings</NavBarOption>
              </OptionGroup> */}
              <OptionGroup
                onClick={() => {
                  setIsStartOpen(false);
                  window.location.reload();
                }}
              >
                <ImgIcon src={Shutdown} />
                <NavBarOption>Restart</NavBarOption>
              </OptionGroup>
            </OptionsWrapper>
          </StartOptionsWrapper>
        )}
        <NavWrapper>
          {elements.map((e) => {
            let isOpenOrMin =
              e.state === stateType.open || e.state === stateType.minimised;
            return (
              isOpenOrMin && (
                <NavigationTab
                  state={e.state}
                  setState={e.setState}
                  OptionText={e.text}
                  icon={e.icon}
                  id={e.id}
                />
              )
            );
          })}
        </NavWrapper>
      </RowContainer>
      <NavClock />
    </NavBar>
  );
};
