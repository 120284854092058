import React from "react";
import styled from "styled-components";
import { setTopIndex } from "../../App";
import { WindowBar } from "../WindowBar";
import { Separator } from "../Separator";
import { FooterBar } from "../FooterBar";
import Draggable from "react-draggable"; // The default
import { useAppContext, stateType } from "../../Context/AppContext";
import Folder from "../../assets/folder.png";

const PageWrapper = styled.div`
  background-color: #bfbfbf;
  width: 45%;
  height: 400px;
  border: 2px solid #fff;
  border-color: #fff #484848 #484848 #fff;
  display: flex;
  flex-direction: column;
  margin-top: 3%;
  margin-left: 2%;
  position: absolute;
`;

const ContactIconsWrapper = styled.div`
  color: #000;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 4px;
  height: 98%;
  border-style: inset;
  border: 2px solid #000;
  border-color: #000 #e2e2e2 #e2e2e2 #000;
  box-sizing: border-box;
  background-color: white;
  overflow-y: scroll;
`;

const Container = styled.div`
  position: relative;
  margin: 1px;
  margin-top: 3px;
  display: flex;
  flex-direction: row;
  font-family: "Roboto Mono", monospace;
  padding: 0px;
  text-align: left;
  height: 100px;
  flex-wrap: wrap;
`;

const IconGroup = styled.a`
  display: flex;
  width: 60px;
  height: 70px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 15px;
  color: #000;
  text-decoration:none &:visited {
    color: #000;
    text-decoration: none;
  }
`;

const IconText = styled.div<{ isSelected: boolean }>`
  font-size: 12px;
  margin-top: 10px;
  text-align: center;
  ${(props) => props.isSelected && `background-color: darkblue; color: #fff;`}
`;

const IconImg = styled.img`
  width: 60px;
`;

export const DesktopWindow = () => {
  const {
    aboutState,
    setDesktopWindowState,
    setAboutState,
    awardsState,
    setAwardsState,
    contactState,
    setContactState,
    featuresState,
    setFeaturesState,
    projectState,
    setProjectState,
    skillsState,
    setSkillsState,
    isTouchDevice,
  } = useAppContext();

  const DragWrapper: React.FunctionComponent = (props) => {
    return isTouchDevice ? (
      <>{props.children}</>
    ) : (
      <Draggable>{props.children}</Draggable>
    );
  };

  return (
    <DragWrapper>
      <PageWrapper
        id="desktopwin"
        onClick={(e) => setTopIndex(e.currentTarget.id)}
      >
        <WindowBar setIsOpen={setDesktopWindowState} headerText={"Desktop"} />
        <Separator />
        <ContactIconsWrapper>
          <Container>
            <IconGroup
              onDoubleClick={() => setAboutState(stateType.open)}
              onClick={() => setAboutState(stateType.open)}
            >
              <IconImg src={Folder} alt={"about"} />
              <IconText isSelected={aboutState === stateType.open}>
                About
              </IconText>
            </IconGroup>
            <IconGroup
              onDoubleClick={() => setProjectState(stateType.open)}
              onClick={() => setProjectState(stateType.open)}
            >
              <IconImg src={Folder} alt={"projects"} />
              <IconText isSelected={projectState === stateType.open}>
                Projects
              </IconText>
            </IconGroup>
            <IconGroup
              onDoubleClick={() => setSkillsState(stateType.open)}
              onClick={() => setSkillsState(stateType.open)}
            >
              <IconImg src={Folder} alt={"skills"} />
              <IconText isSelected={skillsState === stateType.open}>
                Skills
              </IconText>
            </IconGroup>
            <IconGroup
              onDoubleClick={() => setFeaturesState(stateType.open)}
              onClick={() => setFeaturesState(stateType.open)}
            >
              <IconImg src={Folder} alt={"features"} />
              <IconText isSelected={featuresState === stateType.open}>
                Media
              </IconText>
            </IconGroup>
            <IconGroup
              onDoubleClick={() => setAwardsState(stateType.open)}
              onClick={() => setAwardsState(stateType.open)}
            >
              <IconImg src={Folder} alt={"awards"} />
              <IconText isSelected={awardsState === stateType.open}>
                Awards & Talks
              </IconText>
            </IconGroup>
            <IconGroup
              onDoubleClick={() => setContactState(stateType.open)}
              onClick={() => setContactState(stateType.open)}
            >
              <IconImg src={Folder} alt={"contact"} />
              <IconText isSelected={contactState === stateType.open}>
                Contact
              </IconText>
            </IconGroup>
          </Container>
        </ContactIconsWrapper>
        <FooterBar objects={6} />
      </PageWrapper>
    </DragWrapper>
  );
};
