import React from "react";
import styled from "styled-components";

const SeparatorStyle = styled.div`
  margin: 4px 2px;
  height: 2px;
  background-color: #000;
  border-bottom: 1px solid #888888;
  box-sizing: border-box;
  opacity: 0.4;
`;

export const Separator = () => <SeparatorStyle />;
