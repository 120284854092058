import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Speaker from "../assets/speaker.png";

const Container = styled.div`
  margin-right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "MSSS";
  width: 90px;
  height: 28px;
  border: 1px solid #ccc;
  border-color: #858585 #fafafa #fafafa #858585;
  font-size: 10px;
  flex-direction: row;
  background-color: #bebebe;
`;

const SpeakerIcon = styled.img`
  width: 28px;
  margin: 0 2px;
`;

export const NavClock = () => {
  const [time, setTime] = useState({ hours: "", minutes: "", seconds: "" });

  useEffect(() => {
    let d = new Date();
    const intervalID = setInterval(() => {
      setTime({
        hours:
          d.getHours() > 9
            ? d.getHours().toLocaleString()
            : "0" + d.getHours().toLocaleString(),
        minutes:
          d.getMinutes() > 9
            ? d.getMinutes().toLocaleString()
            : "0" + d.getMinutes().toLocaleString(),
        seconds:
          d.getSeconds() > 9
            ? d.getSeconds().toLocaleString()
            : "0" + d.getSeconds().toLocaleString(),
      });
    }, 1000);

    return () => {
      clearInterval(intervalID);
    };
  }, [time]);

  return (
    <Container>
      <SpeakerIcon src={Speaker} /> {time.hours}:{time.minutes}:{time.seconds}
    </Container>
  );
};
