import React, { useEffect } from "react";
import styled from "styled-components";
import { Separator } from "../Separator";
import { WindowBar } from "../WindowBar";
import { useAppContext } from "../../Context/AppContext";
import Card4 from "../../assets/4CARD.png";
import Card3 from "../../assets/3CARD.png";
import Card42 from "../../assets/4CARD2.png";
import CARD10 from "../../assets/10CARD.png";
import CARD102 from "../../assets/10CARD2.png";
import Card8 from "../../assets/8CARD.png";
import AceCard from "../../assets/ACECARD.png";
import BLANK from "../../assets/BLANKSPACECARD.png";
import DECK from "../../assets/DECKCARD.png";
import { setTopIndex } from "../../App";
import Draggable from "react-draggable";

const PageWrapper = styled.div`
  background-color: #c5c6cb;
  width: 95%;
  max-width: 800px;
  border: 2px solid #fff;
  border-color: #fff #484848 #484848 #fff;
  display: flex;
  flex-direction: column;
  margin-top: 5%;
  margin-left: 10px;
  position: absolute;

  @media screen and (min-width: 1024px) {
    margin-left: 9%;
  }
`;

const Container = styled.div`
  background-color: #2f8000;
  position: relative;
  margin: 1px;
  margin-top: 3px;
  display: flex;
  flex-direction: column;
  font-family: "Roboto Mono", monospace;
  min-height: 500px;
  text-align: left;
  flex-wrap: wrap;
  margin: 7px;
  border: 2px inset #fff;
  border-color: #484848 #fff #484848 #484848;
`;

const CardRow = styled.div`
  width: 98%;
  height: 100px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden;
  justify-content: space-between;
  padding: 5px;
`;

const CardRow2 = styled.div`
  width: 98%;
  height: 100px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden;
  justify-content: flex-end;
`;

const CardRow3 = styled.div`
  width: 98%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;
  justify-content: flex-end;
  padding: 5px;
`;

const CardImg = styled.img`
  height: 100px;
  width: 70px;
`;

const CardImg2 = styled.div`
  height: 110px;
  width: 80px;
  border: 1px solid #000;
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  margin-top: 5px;
`;

const CardText = styled.a`
  text-align: center;
  font-size: 10px;
  font-family: "Roboto Mono", monospace;
  width: 90%;

  &:visited {
    color: #000;
  }
`;

const PageWrapper2 = styled.div`
  background-color: #bfbfbf;
  width: 60%;
  height: 215px;
  border: 2px solid #fff;
  border-color: #fff #484848 #484848 #fff;
  display: flex;
  flex-direction: column;
  margin-top: 400px;
  margin-left: 5%;
  position: absolute;
  font-size: 14px;
  font-family: "WindowsCommand";

  @media screen and (min-width: 1024px) {
    margin-top: 350px;
    width: 60%;
    height: 240px;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    margin-top: 350px;
    width: 70%;
    height: 230px;
  }

  @media screen and (min-width: 576px) and (max-width: 767px) {
    margin-top: 361px;
    width: 70%;
    height: 200px;
  }

  @media screen and (min-width: 376px) and (max-width: 575px) {
    margin-top: 420px;
    width: 70%;
    height: 140px;
  }

  @media screen and (max-width: 375px) {
    margin-top: 450px;
    width: 70%;
    height: 120px;
  }
`;

const Container2 = styled.div`
  background-color: #fff;
  height: 100%;
  position: relative;
  margin: 5px;
  display: flex;
  flex-direction: row;
  text-align: left;
  border: 2px solid #ccc;
  border-color: #000 #fff #fff #000;
  padding: 5px;
  overflow-y: scroll;

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 0px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 15px;
    background-color: #bebebe;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #bebebe;
    border: 2px solid #bebebe;
    border-color: #fff #bebebe #000 #e2e2e2;
  }
`;

export const AwardsPage = () => {
  const { setAwardsState, isTouchDevice } = useAppContext();

  useEffect(() => {
    setTopIndex("awards");
  }, []);

  const DragWrapper: React.FunctionComponent = (props) => {
    return isTouchDevice ? (
      <>{props.children}</>
    ) : (
      <Draggable>{props.children}</Draggable>
    );
  };

  return (
    <DragWrapper>
      <PageWrapper id="awards" onClick={(e) => setTopIndex(e.currentTarget.id)}>
        <WindowBar setIsOpen={setAwardsState} headerText={"awards & talks"} />
        <Separator />
        <Container>
          <CardRow>
            <CardImg src={DECK} />
            <div>
              <CardImg src={BLANK} />
              <CardImg src={BLANK} />
              <CardImg src={BLANK} />
              <CardImg src={BLANK} />
            </div>
          </CardRow>
          <CardRow2>
            <CardImg src={Card3} />
            <CardImg src={Card4} />
            <CardImg src={Card42} />
            <CardImg src={Card8} />
            <CardImg src={AceCard} />
            <CardImg src={CARD10} />
            <CardImg src={CARD102} />
          </CardRow2>
          <CardRow3>
            <CardImg2>
              <CardText>
                Rising Technologist @ Booking.com Technology Playmaker Awards
                2020
              </CardText>
            </CardImg2>
            <CardImg2>
              <CardText>Future Stars of Tech Graduate Award 2019</CardText>
            </CardImg2>
            <CardImg2>
              <CardText>Code First Girls: One to Watch 2019</CardText>
            </CardImg2>
            <CardImg2>
              <CardText>Sky Women in Technology Scholar 2018</CardText>
            </CardImg2>
            <CardImg2>
              <CardText>
                Wooga Game Development UNITE Scholarship Winner
              </CardText>
            </CardImg2>
            <CardImg2>
              <CardText>Target Jobs' Undergrad of the Year '17</CardText>
            </CardImg2>
            <CardImg2>
              <CardText>Stemettes Grace Hopper Scholar 2017</CardText>
            </CardImg2>
          </CardRow3>
          <PageWrapper2>
            <WindowBar
              setIsOpen={setAwardsState}
              headerText={"Talks and stuff"}
            />
            <Separator />
            <Container2>
              Talks/Miscellaneous: <br />
              General Assembly (various) <br />
              What Do You Do? Podcast: Entrepreneur Feature
              <br />
              VR Awards 2019 & 2020 Judge <br />
              Athena Show Code Hackathon <br />
              Stemettes Digital Leader Webinar <br />
              Stemettes Summit @ Bank of America/Merill Lynch <br />
              VXR Europe Amsterdam <br />
              De Beers Diversity in Tech <br />
              Tech for Good Impactful VR <br />
              Athena Code Hackathon
              <br />
            </Container2>
          </PageWrapper2>
        </Container>
      </PageWrapper>
    </DragWrapper>
  );
};
