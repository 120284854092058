import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Bin from "../assets/bin.png";
import PC from "../assets/pc.png";
import { useAppContext, stateType } from "../Context/AppContext";

const IconSetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 100px;
  position: fixed;
  z-index: -1;
`;

const IconGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 5px 0;
  width: 90px;
`;
const IconImg = styled.img`
  height: 50px;
  margin: 10px;
`;

const IconText = styled.div<{ isSelected: boolean }>`
  user-select: none;
  ${(props) => props.isSelected && `background-color: darkblue; color: #fff;`}
`;

export const DesktopIcons = () => {
  const [openState, setOpenState] = useState("");
  const ref = useRef<HTMLDivElement>(null);
  const { setDesktopWindowState, setDesktopBinState } = useAppContext();

  const handleOutsideClick = (e: MouseEvent) => {
    if (ref.current && !ref.current.contains(e.target as Node)) {
      setOpenState("");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <IconSetWrapper>
      <IconGroup
        ref={ref}
        onDoubleClick={() => {
          setOpenState("PC");
          setDesktopWindowState(stateType.open);
        }}
      >
        <IconImg src={PC} alt={"PC Icon"} />
        <IconText isSelected={openState === "PC"}>My Computer</IconText>
      </IconGroup>
      <IconGroup
        ref={ref}
        onDoubleClick={() => {
          setOpenState("bin");
          setDesktopBinState(stateType.open);
        }}
      >
        <IconImg src={Bin} alt={"Bin Icon"} />
        <IconText isSelected={openState === "bin"}>Bin</IconText>
      </IconGroup>
    </IconSetWrapper>
  );
};
