import React from "react";
import styled from "styled-components";

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
`;

const HeaderButton = styled.div`
  margin: 0px 4px;
  margin-top: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const HeaderNav = () => {
  const buttonsList = ["File", "Edit", "View", "Options", "Help"];

  return (
    <ButtonsWrapper>
      {buttonsList.map((button, i) => {
        return (
          <HeaderButton key={i}>
            <u>{button.charAt(0)}</u>
            {button.substring(1)}
          </HeaderButton>
        );
      })}
    </ButtonsWrapper>
  );
};
