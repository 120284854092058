import React, { useState, useEffect } from "react";
import "./App.css";
import "./index.css";
import { Navigation } from "./Components/Navigation";
import styled from "styled-components";
import { DesktopIcons } from "./Components/DesktopIcons";
import Cursor from "./assets/cursor.png";
import TimerCursor from "./assets/timercursor.png";
import { AppContext, stateType } from "./Context/AppContext";
import { AboutPage } from "./Components/Pages/AboutPage";
import { ProjectsPage } from "./Components/Pages/ProjectsPage";
import { ContactPage } from "./Components/Pages/ContactPage";
import { SkillsPage } from "./Components/Pages/SkillsPage";
import { MediaPage } from "./Components/Pages/MediaPage";
import { AwardsPage } from "./Components/Pages/AwardsPage";
import { ClippyIcon } from "./Components/DesktopClippy";
import "./enterAnimation.css";
import micwin from "./assets/macwin.png";
import "./assets/fonts/typewriter.css";
import { DesktopWindow } from "./Components/Pages/DesktopWindow";
import { DesktopBin } from "./Components/Pages/DesktopBin";

const PageWrapper = styled.div`
  cursor: url(${Cursor}), auto;
  cursor: url(${Cursor}), pointer;
`;

const LoadingTerminal = styled.div`
  position: absolute;
  z-index: 11;
  background-color: #000;
  width: 100vw;
  height: 100vh;
  cursor: url(${TimerCursor}), auto;
  font-family: "WindowsCommand";
  color: #e2e2e2;
  font-size: 25px;
`;

const LoadingWindows = styled.img`
  display: none;
  @media screen and (min-width: 767px) {
    display: flex;
    cursor: url(${TimerCursor}), auto;
    background-position: center;
    height: 100vh;
    position: absolute;
    object-fit: cover;
    width: 100vw;
    z-index: 10;
    opacity: 1;
  }
`;

export const setTopIndex = (topZ: string) => {
  const AboutDiv = document.getElementById("about");
  const ProjectDiv = document.getElementById("projects");
  const FeaturesDiv = document.getElementById("features");
  const ContactDiv = document.getElementById("contact");
  const SkillsDiv = document.getElementById("skills");
  const AwardsDiv = document.getElementById("awards");
  const desktopWindowState = document.getElementById("desktopwin");
  const desktopBinState = document.getElementById("bin");

  const divs = [
    AboutDiv,
    ProjectDiv,
    FeaturesDiv,
    ContactDiv,
    SkillsDiv,
    AwardsDiv,
    desktopWindowState,
    desktopBinState,
  ];

  divs.map((e) => e && (e.style.zIndex = ""));
  const topZDiv = document.getElementById(topZ);
  topZDiv && (topZDiv.style.zIndex = "1");
};

function App() {
  const [aboutState, setAboutState] = useState(stateType.open);
  const [projectState, setProjectState] = useState(stateType.closed);
  const [featuresState, setFeaturesState] = useState(stateType.closed);
  const [contactState, setContactState] = useState(stateType.closed);
  const [skillsState, setSkillsState] = useState(stateType.closed);
  const [awardsState, setAwardsState] = useState(stateType.closed);
  const [desktopWindowState, setDesktopWindowState] = useState(
    stateType.closed
  );
  const [desktopBinState, setDesktopBinState] = useState(stateType.closed);

  const [currentCursor, setCurrentCursor] = useState(TimerCursor);
  const [topZ, setTopZ] = useState("about");

  const [isLoadingVisible, setIsLoadingVisible] = useState(true);
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  useEffect(() => {
    const loadWin = document.getElementById("loadingwindows");
    loadWin && setIsLoadingVisible(loadWin.style.opacity !== "0");

    if ("ontouchstart" in document.documentElement) {
      setIsTouchDevice(true);
    } else {
      setIsTouchDevice(false);
    }
  }, []);

  return (
    <>
      <AppContext.Provider
        value={{
          aboutState,
          setAboutState,
          projectState,
          setProjectState,
          featuresState,
          setFeaturesState,
          contactState,
          setContactState,
          skillsState,
          setSkillsState,
          awardsState,
          setAwardsState,
          topZ,
          setTopZ,
          currentCursor,
          setCurrentCursor,
          desktopWindowState,
          setDesktopWindowState,
          desktopBinState,
          setDesktopBinState,
          isTouchDevice,
        }}
      >
        <PageWrapper>
          <LoadingTerminal className={"enterTerminal"}>
            <div className={"terminaltype"}>C:\>boot 9500.IMA </div>
            <br />
            <div className={"terminaltype2"}>Opening image file: 9500.IMA </div>
            <br />
            <div className={"terminaltype3"}>Booting... </div>
            <br />
            <div className={"terminaltype4"}>
              Starting Windows 95... <br />
            </div>
            <br />
            <div className={"cursor"}>_</div>
          </LoadingTerminal>
          {isLoadingVisible && (
            <LoadingWindows
              id={"loadingwindows"}
              src={micwin}
              className={"enterWindows"}
            />
          )}
          <DesktopIcons />
          <ClippyIcon />
          {aboutState === stateType.open && <AboutPage />}
          {projectState === stateType.open && <ProjectsPage />}
          {contactState === stateType.open && <ContactPage />}
          {skillsState === stateType.open && <SkillsPage />}
          {featuresState === stateType.open && <MediaPage />}
          {awardsState === stateType.open && <AwardsPage />}
          {desktopWindowState === stateType.open && <DesktopWindow />}
          {desktopBinState === stateType.open && <DesktopBin />}
          <Navigation />
        </PageWrapper>
      </AppContext.Provider>
    </>
  );
}

export default App;
