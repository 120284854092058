import React from "react";
import styled from "styled-components";
import Clippy from "../assets/clippy.png";
import ClippyText from "../assets/clippytext.png";
import "../assets/clippy.css";

const IconSetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: right;
  width: 160px;
  position: fixed;
  z-index: 1;
  bottom: 50px;
  right: 10px;
`;

const IconImg = styled.img`
  height: 90px;
  width: 80px;
  margin: 0 80px;
`;

const TextImg = styled.img`
  width: 160px;
  height: 50px;
`;

const IconText = styled.div`
  position: absolute;
  margin: 11px;
  margin-top: -45px;
  font-family: "msss";
  font-size: 9px;
`;

export const ClippyIcon = () => {
  return (
    <IconSetWrapper>
      <div className={"scale-in-center"}>
        <TextImg src={ClippyText} />
        <IconText>
          Hey there! For more info, click on the Start button.
        </IconText>
      </div>
      <IconImg className={"swirl-in-fwd"} src={Clippy} alt={"Clippy"} />
    </IconSetWrapper>
  );
};
