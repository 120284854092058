import React, { useEffect } from "react";
import styled from "styled-components";
import { Separator } from "../Separator";
import { useAppContext } from "../../Context/AppContext";
import { WindowBar } from "../WindowBar";
import Flowers from "../../assets/flowers.png";
import Medicall from "../../assets/medicall.png";
import Freyoga from "../../assets/freyoga.png";
import Vrcalm from "../../assets/vrcalm.png";
import Naturehop from "../../assets/naturehop.png";
import Arrow from "../../assets/arrow.png";
import DownArrow from "../../assets/downarrow.png";
import { FooterBar } from "../FooterBar";
import { setTopIndex } from "../../App";
import Draggable from "react-draggable";

const PageWrapper = styled.div`
  background-color: #bfbfbf;
  width: 80%;
  max-width: 800px;
  height: 600px;
  border: 2px solid #fff;
  border-color: #fff #484848 #484848 #fff;
  display: flex;
  flex-direction: column;
  margin-top: 7%;
  margin-left: 14%;
  position: absolute;

  @media screen and (min-width: 1024px) {
    margin-top: 4%;
  }
`;

const Container = styled.div`
  position: relative;
  margin: 1px;

  margin-top: 3px;
  display: flex;
  flex-direction: column;
  font-family: "Roboto Mono", monospace;
  padding: 20px;
  text-align: left;
`;

const AboutPageWrapper = styled.div`
  color: #000;
  border: 2px solid #bebebe;
  border-color: #000 #000 #fff #000;
  display: flex;
  flex-direction: row;
  height: 98%;
  margin: 3px;
  border-style: inset;
  box-sizing: border-box;
  background-color: white;
  overflow-y: scroll;

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 0px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 15px;
    background-color: #bebebe;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #bebebe;
    border: 2px solid #bebebe;
    border-color: #fff #bebebe #000 #e2e2e2;
  }
`;

const HeaderText = styled.div`
  font-size: 25px;
`;

const SubText = styled.a`
  letter-spacing: 2px;
  margin: 10px 0;
  font-size: 14px;
  max-width: 80%;
  text-align: center;
  color: #fff;

  &:visited {
    color: #fff;
  }
`;

const WorkWrapper = styled.div`
  margin-bottom: 20px;
`;

const WorkGrid = styled.div`
  display: flex;
`;

const WorkItem = styled.div<{ background: string }>`
  flex: 1;
  width: 50%;
  height: 150px;
  background-color: red;
  margin-right: 20px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${(props) => props.background}) center no-repeat;
  background-size: cover;
  color: rgba(255, 255, 255, 07);
  position: relative;
  border-radius: 10px;
`;

const BodyText = styled.div`
  font-size: 13px;
  margin: 20px 0;
`;

const ScrollArrow = styled.img`
  width: 16px;
  height: 15px;
  position: absolute;
  top: 51px;
  right: 1px;
`;

const DownScrollArrow = styled.img`
  width: 17px;
  height: 15px;
  position: absolute;
  bottom: 25px;
  right: 2.5px;
`;

export const ProjectsPage = () => {
  useEffect(() => {
    setTopIndex("projects");
  }, []);

  const { setProjectState, isTouchDevice } = useAppContext();

  const DragWrapper: React.FunctionComponent = (props) => {
    return isTouchDevice ? (
      <>{props.children}</>
    ) : (
      <Draggable>{props.children}</Draggable>
    );
  };

  return (
    <DragWrapper>
      <PageWrapper
        id="projects"
        onClick={(e) => {
          setTopIndex(e.currentTarget.id);
        }}
      >
        <WindowBar
          setIsOpen={setProjectState}
          headerText={"some stuff i've done"}
        />
        <Separator />
        <ScrollArrow src={Arrow} />
        <AboutPageWrapper>
          <Container>
            <HeaderText>projects + work</HeaderText>
            <BodyText>
              I have previously worked at various startups, The Walt Disney
              Company, Deutsche Bank and DWS Group. Currently working as a snr.
              frontend developer at{" "}
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="http://www.defty.com"
              >
                defty
              </a>
              . Here are some examples of what I'm working on and what I've done
              in my spare time.
            </BodyText>
            <WorkWrapper>
              <WorkGrid>
                <WorkItem background={Vrcalm}>
                  <SubText href={"http://www.vrcalm.co.uk"} target={"_blank"}>
                    vrcalm
                  </SubText>
                </WorkItem>
                <WorkItem background={Freyoga}>
                  <SubText href={"http://www.freyoga.co.uk"} target={"_blank"}>
                    freyoga
                  </SubText>
                </WorkItem>
              </WorkGrid>
              <WorkGrid>
                <WorkItem background={Medicall}>
                  <SubText
                    target="_blank"
                    href={
                      "https://www.behance.net/gallery/34262971/mediCall-UI-Design"
                    }
                  >
                    medicall
                  </SubText>
                </WorkItem>
                <WorkItem background={Flowers}>
                  <SubText href={"#"}>flowers ARCore app</SubText>
                </WorkItem>
              </WorkGrid>
              <WorkGrid>
                <WorkItem background={Naturehop}>
                  <SubText
                    target={"_blank"}
                    href={"https://github.com/hannahblair/NatureHop"}
                  >
                    nature hop VR game
                  </SubText>
                </WorkItem>
              </WorkGrid>
            </WorkWrapper>
          </Container>
        </AboutPageWrapper>
        <FooterBar objects={5} />
        <DownScrollArrow src={DownArrow} />
      </PageWrapper>
    </DragWrapper>
  );
};
