import React from "react";
import styled from "styled-components";
import { IconSet } from "./IconSet";
import { stateType } from "../Context/AppContext";
import cmdLogo from "../assets/cmdlogo.png";

const Header = styled.div`
  display: flex;
  height: 20px;
  background: #0a246a; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    #0a246a 0%,
    #85a7d3 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #0a246a 0%,
    #85a7d3 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #0a246a 0%,
    #85a7d3 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0a246a', endColorstr='#85a7d3',GradientType=1 ); /* IE6-9 */
  align-items: center;
  padding: 0 5px;
  justify-content: space-between;
`;

const HeaderText = styled.div`
  color: #fff;
`;

const CmdLogoStyle = styled.img`
  width: 17px;
  margin-right: 6px;
`;

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

type WindowProps = {
  setIsOpen: (state: stateType) => void;
};

export const TerminalBar = ({ setIsOpen }: WindowProps) => {
  return (
    <div>
      <Header>
        <HeadingContainer>
          <CmdLogoStyle src={cmdLogo} alt={"cmd logo"} />
          <HeaderText>D:\WIN\system32\cmd.exe</HeaderText>
        </HeadingContainer>
        <IconSet setState={setIsOpen} />
      </Header>
    </div>
  );
};
