import React, { useEffect } from "react";
import styled from "styled-components";
import { Separator } from "../Separator";
import { useAppContext } from "../../Context/AppContext";
import { WindowBar } from "../WindowBar";
import LinkedIn from "../../assets/Linkedin.png";
import Twitter from "../../assets/twtitter.png";
import Github from "../../assets/github.png";
import MailIcon from "../../assets/MailIcon.png";
import Medium from "../../assets/Medium.png";
import { FooterBar } from "../FooterBar";
import { setTopIndex } from "../../App";
import Draggable from "react-draggable"; // The default

const PageWrapper = styled.div`
  background-color: #bfbfbf;
  width: 60%;
  height: 400px;
  border: 2px solid #fff;
  border-color: #fff #484848 #484848 #fff;
  display: flex;
  flex-direction: column;
  margin-top: 4%;
  margin-left: 10%;
  position: absolute;
`;

const Container = styled.div`
  position: relative;
  margin: 1px;
  margin-top: 3px;
  display: flex;
  flex-direction: row;
  font-family: "Roboto Mono", monospace;
  padding: 0px;
  text-align: left;
  height: 100px;
  flex-wrap: wrap;
`;

const ContactIconsWrapper = styled.div`
  color: #000;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 4px;
  height: 98%;
  border-style: inset;
  border: 2px solid #000;
  border-color: #000 #e2e2e2 #e2e2e2 #000;
  box-sizing: border-box;
  background-color: white;
  overflow-y: scroll;
`;

const IconGroup = styled.a`
  display: flex;
  width: 60px;
  height: 70px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 15px;
  color: #000;
  text-decoration:none &:visited {
    color: #000;
    text-decoration: none;
  }
`;

const IconText = styled.div`
  font-size: 12px;
  margin-top: 10px;
`;

const IconImg = styled.img`
  width: 60px;
`;

export const ContactPage = () => {
  useEffect(() => {
    setTopIndex("contact");
  }, []);

  const { setContactState, isTouchDevice } = useAppContext();

  const DragWrapper: React.FunctionComponent = (props) => {
    return isTouchDevice ? (
      <>{props.children}</>
    ) : (
      <Draggable>{props.children}</Draggable>
    );
  };

  return (
    <DragWrapper>
      <PageWrapper
        id="contact"
        onClick={(e) => setTopIndex(e.currentTarget.id)}
      >
        <WindowBar
          setIsOpen={setContactState}
          headerText={"get_in_touch.txt"}
        />
        <Separator />
        <ContactIconsWrapper>
          <Container>
            <IconGroup href={"http://www.twitter.com/erhannah"} target="_blank">
              <IconImg src={Twitter} alt={"twitter"} />
              <IconText>Twitter</IconText>
            </IconGroup>
            <IconGroup
              href={"http://www.linkedin.com/in/hannahmblair"}
              target="_blank"
            >
              <IconImg src={LinkedIn} alt={"linkedin"} />
              <IconText>LinkedIn</IconText>
            </IconGroup>
            <IconGroup
              href={"http://www.github.com/hannahblair"}
              target="_blank"
            >
              <IconImg src={Github} alt={"GitHub"} />
              <IconText>GitHub</IconText>
            </IconGroup>
            <IconGroup
              href={"http://www.medium.com/@hannahblair"}
              target="_blank"
            >
              <IconImg src={Medium} alt={"Medium"} />
              <IconText>Medium</IconText>
            </IconGroup>
            <IconGroup href={"mailto: hello@hannahblair.co.uk"} target="_blank">
              <IconImg src={MailIcon} alt={"Email"} />
              <IconText>Email</IconText>
            </IconGroup>
          </Container>
        </ContactIconsWrapper>
        <FooterBar objects={5} />
      </PageWrapper>
    </DragWrapper>
  );
};
