import React, { useContext } from "react";

export enum stateType {
  "open",
  "minimised",
  "closed",
}

const initalValues = {
  aboutState: stateType.open,
  setAboutState: (_state: stateType) => {},
  projectState: stateType.closed,
  setProjectState: (_state: stateType) => {},
  featuresState: stateType.closed,
  setFeaturesState: (_state: stateType) => {},
  contactState: stateType.closed,
  setContactState: (_state: stateType) => {},
  skillsState: stateType.closed,
  setSkillsState: (_state: stateType) => {},
  awardsState: stateType.closed,
  setAwardsState: (_state: stateType) => {},
  topZ: "about",
  setTopZ: (_topZ: string) => {},
  currentCursor: "",
  setCurrentCursor: (_currentCursor: string) => {},
  desktopWindowState: stateType.closed,
  setDesktopWindowState: (_state: stateType) => {},
  desktopBinState: stateType.closed,
  setDesktopBinState: (_state: stateType) => {},
  isTouchDevice: false,
};

export const AppContext = React.createContext(initalValues);

export const useAppContext = () => useContext(AppContext);
