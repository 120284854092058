import React, { useEffect } from "react";
import styled from "styled-components";
import { TerminalBar } from "../TerminalBar";
import { useAppContext } from "../../Context/AppContext";
import "../../assets/fonts/typewriter.css";
import { setTopIndex } from "../../App";
import Draggable from "react-draggable"; // The default

const PageWrapper = styled.div`
  background-color: #bfbfbf;
  width: 90%;
  height: 300px;
  max-width: 600px;
  border: 2px solid #fff;
  border-color: #fff #484848 #484848 #fff;
  display: flex;
  flex-direction: column;
  margin-top: 10%;
  margin-left: 8%;
  position: absolute;
`;

const Container = styled.div`
  position: relative;
  margin: 1px;
  margin-top: 3px;
  display: flex;
  flex-direction: column;
  text-align: left;
  max-height: 100%;
`;

const AboutPageWrapper = styled.div`
  color: #a8a8a8;
  background-color: #000;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 98%;
  border-style: inset;
  border: 2px solid #000;
  border-color: #bebebe;
  box-sizing: border-box;
`;

const BodyText = styled.div`
  font-size: 16px;
  letter-spacing: 1px;
  margin-bottom: 10px;
`;

const SkillsText = styled.div`
  font-size: 18px;
  letter-spacing: 1px;
  margin-bottom: 10px;
  color: yellow;
  text-align: left;
`;

const SkillsGrid = styled.div`
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 13px;
  letter-spacing: 1px;
  margin-bottom: 10px;
  color: yellow;

  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const SkillsPage = () => {
  const { setSkillsState, isTouchDevice } = useAppContext();

  useEffect(() => {
    setTopIndex("skills");
  }, []);

  const DragWrapper: React.FunctionComponent = (props) => {
    return isTouchDevice ? (
      <>{props.children}</>
    ) : (
      <Draggable>{props.children}</Draggable>
    );
  };

  return (
    <DragWrapper>
      <PageWrapper id="skills" onClick={(e) => setTopIndex(e.currentTarget.id)}>
        <TerminalBar setIsOpen={setSkillsState} />
        <AboutPageWrapper>
          <Container>
            <BodyText>Microsoft(R) Windows DOS</BodyText>
            <BodyText>{`<C>Copyright Microsoft Corp 1990-1995.`}</BodyText>
            <BodyText className={"typewriter"}>
              {`C:\\Users\\Hanz>_`}
              <BodyText
                style={{ animationDelay: "1s", display: "inline" }}
                className={"typewriter"}
              >
                {` cd Skills_and_Software`}
              </BodyText>
            </BodyText>

            <BodyText
              style={{ animationDelay: "1.5s" }}
              className={"typewriter"}
            >
              {`C:\\Users\\Hanz\\Skills_and_Software>_ dir`}
              <br />
            </BodyText>
            <SkillsGrid
              className={"typewriter2"}
              style={{ animationDelay: "3.5s" }}
            >
              <SkillsText>{`React.js`}</SkillsText>
              <SkillsText>{`React Native`}</SkillsText>
              <SkillsText>{`Node.js`}</SkillsText>
              <SkillsText>{`C#`}</SkillsText>
              <SkillsText>{`Java`}</SkillsText>
              <SkillsText>{`JavaScript`}</SkillsText>
              <SkillsText>{`TypeScript`}</SkillsText>
              <SkillsText>{`Unity`}</SkillsText>
              <SkillsText>{`UI/UX Design`}</SkillsText>
              <SkillsText>{`AR/VR Design`}</SkillsText>
              <SkillsText>{`Blender`}</SkillsText>
              <SkillsText>{`Maya`}</SkillsText>
              <SkillsText>{`Cinema 4D`}</SkillsText>
              <SkillsText>{`Git`}</SkillsText>
              <SkillsText>{`Shopify`}</SkillsText>
              <SkillsText>{`Adobe Suite`}</SkillsText>
            </SkillsGrid>
          </Container>
        </AboutPageWrapper>
      </PageWrapper>
    </DragWrapper>
  );
};
