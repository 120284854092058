import React from "react";
import styled from "styled-components";

const BodyText = styled.div`
  margin: 10px 0 2px 0;
  font-size: 14px;
  font-weight: 500;
`;

const Subtitle = styled.div`
  font-size: 10px;
  font-weight: 500;
`;

const LinkStyle = styled.a`
  color: #000;
  &:visited {
    color: #000;
    text-decoration: none;
  }
`;

type MediaProps = {
  body: string;
  subtitle: string;
  link: string;
};

export const MediaItem = ({ body, subtitle, link }: MediaProps) => (
  <BodyText>
    <LinkStyle target={"_blank"} rel="noopener noreferrer" href={link}>
      {body}
    </LinkStyle>
    <Subtitle>{subtitle}</Subtitle>
  </BodyText>
);
