import React from "react";
import styled from "styled-components";
import { setTopIndex } from "../../App";
import { WindowBar } from "../WindowBar";
import { Separator } from "../Separator";
import { FooterBar } from "../FooterBar";
import Draggable from "react-draggable"; // The default
import { useAppContext } from "../../Context/AppContext";
import Folder from "../../assets/folder.png";

const PageWrapper = styled.div`
  background-color: #bfbfbf;
  width: 250px;
  height: 250px;
  border: 2px solid #fff;
  border-color: #fff #484848 #484848 #fff;
  display: flex;
  flex-direction: column;
  margin-top: 4%;
  margin-left: 10%;
  position: absolute;
`;

const ContactIconsWrapper = styled.div`
  color: #000;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 4px;
  height: 98%;
  border-style: inset;
  border: 2px solid #000;
  border-color: #000 #e2e2e2 #e2e2e2 #000;
  box-sizing: border-box;
  background-color: white;
  overflow-y: scroll;
`;

const Container = styled.div`
  position: relative;
  margin: 1px;
  margin-top: 3px;
  display: flex;
  flex-direction: row;
  font-family: "Roboto Mono", monospace;
  padding: 0px;
  text-align: left;
  height: 100px;
  flex-wrap: wrap;
`;

const IconGroup = styled.a`
  display: flex;
  width: 60px;
  height: 70px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 15px;
  color: #000;
  text-decoration:none &:visited {
    color: #000;
    text-decoration: none;
  }
`;

const IconText = styled.div`
  font-size: 12px;
  margin-top: 10px;
  text-align: center;
`;

const IconImg = styled.img`
  width: 60px;
`;

export const DesktopBin = () => {
  const { setDesktopBinState, isTouchDevice } = useAppContext();

  const DragWrapper: React.FunctionComponent = (props) => {
    return isTouchDevice ? (
      <>{props.children}</>
    ) : (
      <Draggable>{props.children}</Draggable>
    );
  };

  return (
    <DragWrapper>
      <PageWrapper id="bin" onClick={(e) => setTopIndex(e.currentTarget.id)}>
        <WindowBar
          setIsOpen={setDesktopBinState}
          headerText={"Stuff for the bin"}
        />
        <Separator />
        <ContactIconsWrapper>
          <Container>
            <IconGroup
              href={"https://www.behance.net/hannahblair"}
              target="_blank"
            >
              <IconImg src={Folder} alt={"twitter"} />
              <IconText>My Ancient Graphics A Level Work</IconText>
            </IconGroup>
            <IconGroup
              href={"https://github.com/hannahblair?tab=repositories"}
              target="_blank"
            >
              <IconImg src={Folder} alt={"linkedin"} />
              <IconText>My Abandoned Side Projects</IconText>
            </IconGroup>
          </Container>
        </ContactIconsWrapper>
        <FooterBar objects={2} />
      </PageWrapper>
    </DragWrapper>
  );
};
