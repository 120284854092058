import React, { useEffect } from "react";
import styled from "styled-components";
import { Separator } from "../Separator";
import Hanz from "../../assets/hanz.jpg";
import { WindowBar } from "../WindowBar";
import { useAppContext } from "../../Context/AppContext";
import { FooterBar } from "../FooterBar";
import { setTopIndex } from "../../App";
import "../../assets/minimise.css";
import Draggable from "react-draggable"; // The default

const PageWrapper = styled.div`
  background-color: #bfbfbf;
  width: 80%;
  max-width: 600px;
  border: 2px solid #fff;
  border-color: #fff #484848 #484848 #fff;
  display: flex;
  flex-direction: column;
  margin-top: 10%;
  margin-left: 15%;
  position: absolute;

  @media screen and (max-width: 375px) {
    margin-top: 10px;
    margin-left: 25px;
    width: 90%;
  }
`;

const Container = styled.div`
  position: relative;
  margin: 1px;
  margin-top: 3px;
  display: flex;
  flex-direction: row;
  font-family: "Roboto Mono", monospace;
  padding: 20px;
  min-height: 300px;
  text-align: left;
  flex-wrap: wrap;

  @media screen and (max-width: 767px) {
    padding: 10px;
  }
`;

const AboutPageWrapper = styled.div`
  color: #fff;
  display: flex;
  margin: 3px;
  flex-direction: row;
  flex-wrap: wrap;
  height: 98%;
  border-style: inset;
  border: 2px solid #bebebe;
  border-color: #000 #fff #fff #000;
  box-sizing: border-box;
  background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
  background: linear-gradient(-225deg, #473b7b, #3584a7 51%, #30d2be);
  @media screen and (max-width: 767px) {
    justify-content: center;
  }
`;

const TextWrapper = styled.div`
  width: 50%;
  @media screen and (max-width: 767px) {
    text-align: center;
    margin: 0 auto;
    width: 80%;
  }
`;

const HeaderText = styled.div`
  font-size: 25px;
`;

const SubText = styled.div`
  letter-spacing: 2px;
  margin: 10px 0;
  font-size: 12px;
`;

const BodyText = styled.div`
  font-size: 13px;
`;

const Link = styled.a`
  color: #fff;
  &::visited {
    color: #fff;
  }
`;

const PicOfMe = styled.img`
  flex: 0 0 0px;
  border-radius: 50%;
  height: auto;
  width: 200px;
  height: 200px;
  margin: 15px 20px;
  box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.3),
    0px -1px 0px rgba(0, 0, 0, 0.7);

  @media screen and (max-width: 767px) {
    margin: 0 auto;
    margin-top: 20px;
  }
`;

export const AboutPage = () => {
  useEffect(() => {
    setTopIndex("about");
  }, []);

  const { setAboutState, isTouchDevice } = useAppContext();

  const DragWrapper: React.FunctionComponent = (props) => {
    return isTouchDevice ? (
      <>{props.children}</>
    ) : (
      <Draggable>{props.children}</Draggable>
    );
  };

  return (
    <DragWrapper>
      <PageWrapper id="about" onClick={(e) => setTopIndex(e.currentTarget.id)}>
        <WindowBar setIsOpen={setAboutState} headerText={"welcome 2 my page"} />
        <Separator />
        <AboutPageWrapper>
          <Container>
            <TextWrapper>
              <HeaderText>hi! i'm hannah.</HeaderText>
              <SubText>DEVELOPER AND DESIGNER</SubText>
              <BodyText>
                I'm fascinated by creative technology, and love to create
                visually immersive user experiences with design and code. <p />I
                especially enjoy Web + Mobile Development, Virtual + Augmented
                Reality Development, and UI/UX Design.
              </BodyText>
              <br />
              <BodyText>
                Also currently on the London{" "}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href={"https://www.transformativetech.org/"}
                >
                  TransTech Committee,
                </Link>{" "}
                cofounder of{" "}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href={"https://www.vrcalm.co.uk/"}
                >
                  vrcalm
                </Link>{" "}
                and judge for the 2020{" "}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://vrawards.aixr.org/"
                >
                  VR Awards
                </Link>
                .
              </BodyText>
            </TextWrapper>
            <PicOfMe src={Hanz} alt={"Me having a laugh"} />
          </Container>
        </AboutPageWrapper>
        <FooterBar objects={3} />
      </PageWrapper>
    </DragWrapper>
  );
};
