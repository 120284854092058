import React from "react";
import styled from "styled-components";
import Expand from "../assets/Expand.png";

const WindowStyle = styled.div`
  background-color: #bfbfbf;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  font-family: "MSSS";
`;

const SubText = styled.div`
  color: #000;
  font-family: "MSSS";
  font-size: 8px;
  text-decoration: bold;
`;

const Container = styled.div`
  margin: 4px;
  margin-top: -1px;
  padding: 1px;
  border: 1px inset #ccc;
  border-color: #ccc #fff #fff #ccc;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 15px;
`;

const ExpandIcon = styled.img`
  height: 15px;
  margin-bottom: 6px;
  margin-right: -1px;
  position: relative;
`;

type WindowProps = {
  objects: number;
};

export const FooterBar = ({ objects }: WindowProps) => {
  return (
    <div>
      <WindowStyle>
        <Container>
          <SubText>{objects} object(s)</SubText>
          <div>
            <ExpandIcon src={Expand} />
          </div>
        </Container>
      </WindowStyle>
    </div>
  );
};
